<template>
  <div class="d-flex ml-auto">
    <!--<ChangeLogBell />-->

    <v-menu offset-y :close-on-content-click="false" nudge-bottom="10">
      <template v-slot:activator="{ on, attrs }">
        <jb-btn
          text
          v-bind="attrs"
          v-on="on"
          :icon="$vuetify.breakpoint.sm"
          class="text-none ml-auto font-weight-medium mr-1"
        >
          <v-icon class="material-icons-outlined"> support </v-icon>
          <span v-if="$vuetify.breakpoint.mdAndUp" class="ml-1"> Help </span>
        </jb-btn>
      </template>

      <list>
        <list-item href="https://support.jobboardfire.com/" target="_blank">
          <v-icon class="material-icons-outlined" slot="icon"> info </v-icon>
          Help Center
        </list-item>

        <list-item @click.native="handleLiveChat">
          <v-icon class="material-icons-outlined" slot="icon">
            question_answer
          </v-icon>
          Live Chat
        </list-item>
      </list>
    </v-menu>

    <!--<v-menu offset-y :close-on-content-click="false" nudge-bottom="10">
      <template v-slot:activator="{ on, attrs }">
        <jb-btn
          text
          v-bind="attrs"
          v-on="on"
          :icon="$vuetify.breakpoint.sm"
          class="text-none ml-auto font-weight-medium mr-1"
        >
          <v-icon class="material-icons-outlined"> favorite_border </v-icon>
          <span v-if="$vuetify.breakpoint.mdAndUp" class="ml-1">
            Feedback
          </span>
        </jb-btn>
      </template>

      <list>
        <list-item
          href="https://feedback.jobboardfire.com/roadmap"
          target="_blank"
        >
          <v-icon class="material-icons-outlined" slot="icon">
            account_tree
          </v-icon>
          Explore Roadmap
        </list-item>

        <list-item href="https://feedback.jobboardfire.com/" target="_blank">
          <v-icon class="material-icons-outlined" slot="icon">
            announcement
          </v-icon>
          Share Feedback
        </list-item>
      </list>
    </v-menu>-->
  </div>
</template>

<script>
import List from '@/components/List/List'
import ListItem from '@/components/List/ListItem'
import JbBtn from '@/components/JbBtn/JbBtn'
// import ChangeLogBell from '@/components/NavBar/ChangeLog/ChangeLogBell'

export default {
  name: 'jb-menu',
  components: {
    ListItem,
    List,
    JbBtn,
    // ChangeLogBell,
  },
  methods: {
    handleLiveChat() {
      window.FrontChat('show')
    },
  },
}
</script>
